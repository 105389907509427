@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@300&display=swap");

* {
  font-family: "League Spartan";
}

:root {
  --main-bg-color: #1b1b1b;
  --main-text-color: #ffffff;
  --accent-color: #007bff;
  --link-color: #ffffff;
  --lightmode-bg-color: #ffffff;
  --lightmode-text-color: #000000;
  --lightmode-link-color: #1b1b1b;
}

#light {
  background-color: var(--lightmode-bg-color);
  color: var(--lightmode-text-color);
}
#dark {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}
#light .switch label {
  color: black;
}
#dark .switch label {
  color: white;
}

header {
  display: flex;
  padding: 15px 0px 0px 15px;
}
h1 {
  font-size: 60px;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 24px;
  padding: 25px 15px 15px 25px;
}

.switch {
  padding: 25px;
}

.resume {
  border-style : solid;
  word-wrap: normal;
}

.experience, .pdf{
  margin : 0px 75px 0px 75px;
}

.section-title {
  padding-bottom: 0;
  padding-top: 0;
}

#light a:link {
  text-decoration: none;
}
#dark a:link {
  text-decoration: none;
}

#dark a:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  background: var(--link-color);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
#light a:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  background-color: var(--lightmode-link-color);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

#dark nav a:hover:after {
  width: 100%;
  left: 0;
}
#light nav a:hover:after {
  width: 100%;
  left: 0;
}

.aboutMe {
  display: flex;
  margin-top: 35px;
  flex-direction: column;
  align-items: center;
}
.aboutMeText {
  inline-size: 750px;
  font-size: 24px;
  margin: 50px 25px 0px 0px;
  padding: 25px 25px 25px 25px;
}

#projectsContainer {
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 25px;
}
.project {
  width: 700px;
  height: 500px;
  border-radius: 25px;
}
/* Avatar */
#dark .avatar {
  border-radius: 300px;
  background-color: var(--link-color);
  display: flex;
  justify-content: center;
  height: 250px;
  width: 250px;
  margin-left: 38%;
  margin-right: 38%;
}
#light .avatar {
  border-radius: 300px;
  background-color: var(--lightmode-link-color);
  display: flex;
  justify-content: center;
  height: 250px;
  width: 250px;
  margin-left: 38%;
  margin-right: 38%;
}

/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.social-media-list li:after {
  content: "";
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}
.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

#dark .social-media-list li a {
  color: black;
}
#light .social-media-list li a {
  color: var(--main-text-color);
}

#dark .social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: var(--main-bg-color);
  background-color: var(--lightmode-bg-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#light .social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#dark .social-media-list li:hover {
  background-color: var(--main-bg-color);
}
#light .social-media-list li:hover {
  background-color: var(--link-color);
}

#dark .social-media-list li:hover a {
  color: white;
}
#light .social-media-list li:hover a {
  color: #000;
}

/* repo links */
#dark .repoLinks {
  color: var(--main-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px 25px 0;
}
#light .repoLinks {
  color: var(--lightmode-text-color);
  flex-direction: column;
  align-items: center;
  padding: 0 25px 25px 0;
}

#dark .repoLinks:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  background: var(--link-color);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
#light .repoLinks:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  background: var(--lightmode-link-color);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

#dark .repoLinks:hover:after {
  width: 100%;
  left: 0;
}
#light .repoLinks:hover:after {
  width: 100%;
  left: 0;
}

#grayscale {
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;
}
#grayscale:hover {
  filter: grayscale(0);
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 24px;
  }

  nav {
    font-size: 14px;
  }

  nav a {
    font-size: 14px;
  }

  .aboutMeText {
    inline-size: 300px;
    font-size: 14px;
    margin: 15px;
  }

  .aboutMe img {
    margin-left: 10%;
    margin-right: 10%;
  }

  .project {
    font-size: 14px;
    inline-size: 300px;
    width: 300px;
    height: 250px;
  }

  .repoLinks {
    padding-right: 0;
    
  }
  /* .resume { 
    font-size: 14px;
    padding: 15px 15px 15px 15px;

  } */
  .experience, .pdf{
    margin : 0 15px 0 15px;
}
}

.switch {
  color: white;
  display: flex;
  flex-direction: column-reverse;
  align-items: end;
  margin: 10px;
}
#dark .pdf {
  border-radius: 30px;
  color: var(--main-bg-color);
  background-color: var(--main-text-color);
}

#light .pdf {
  border-radius: 30px;
  color: var(--lightmode-bg-color);
  background-color: var(--lightmode-text-color);
}

#dark .header {
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
}
#dark .contact-info {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  padding: 25px 25px 25px 25px;
  justify-content: center;
}
#dark .summary {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  padding: 25px 25px 25px 25px;
}
#dark .section-title {
  padding: 25px 25px 25px 25px;
  font-size: 24px;
  font-weight: bold;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);

  display: flex;
  justify-content: center;
}
#dark .experience {
  font-size: 24px;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  padding: 25px 25px 25px 25px;
}
/* #dark .education {
  font-size: 24px;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  padding: 25px 25px 25px 25px;
}
#dark .certifications {
  font-size: 24px;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  padding: 25px 25px 25px 25px;
} */

#light .header {
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: var(--lightmode-text-color);
  background-color: var(--lightmode-bg-color);
}
#light .contact-info {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: var(--lightmode-text-color);
  background-color: var(--lightmode-bg-color);
  padding: 25px 25px 25px 25px;
  justify-content: center;
}
#light .summary {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  color: var(--lightmode-text-color);
  background-color: var(--lightmode-bg-color);
  padding: 25px 25px 25px 25px;
}
#light .section-title {
  padding: 25px 25px 25px 25px;
  font-size: 24px;
  font-weight: bold;
  color: var(--lightmode-text-color);
  background-color: var(--lightmode-bg-color);

  display: flex;
  justify-content: center;
}
#light .experience {
  font-size: 24px;
  color: var(--lightmode-text-color);
  background-color: var(--lightmode-bg-color);
  padding: 25px 25px 25px 25px;
}
/* #light .education {
  font-size: 24px;
  color: var(--lightmode-text-color);
  background-color: var(--lightmode-bg-color);
  padding: 25px 25px 25px 25px;
} */
/* #light .certifications {
  font-size: 24px;
  color: var(--lightmode-text-color);
  background-color: var(--lightmode-bg-color);
  padding: 25px 25px 25px 25px;
} */

